@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body, .grid-container {
  height: 100%;
  margin: 0;
}

.grid-container * {
  //border: 1px solid red;
  position: relative;
}

.grid-container *:after {
  //content: attr(class);
  position: absolute;
  top: 0;
  left: 0;
}

.grid-container {
  display: grid;
  grid-template-columns: 20px 1fr 0.1fr 2fr 2fr 2fr 0.1fr 1fr 20px;
  grid-template-rows: 20px 0.5fr 0.1fr 1fr 1fr 0.1fr 0.3fr 20px;
  grid-template-areas:
          ". . . . . . . . ."
          ". logo . location-name location-name location-name . . ."
          ". . . . . . . . ."
          ". . . content content content . . ."
          ". . . content content content . . ."
          ". . . . . . . . ."
          ". status . . . . . timer ."
          ". . . . . . . . .";
}

.logo {
  grid-area: logo;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }
}

.location-name {
  grid-area: location-name;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 3em;
    color: var(--theme-primary-color);
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "block-1 block-2 block-3" "block-4 block-5 block-6";
  grid-area: content;
}

.block-1 {
  grid-area: block-1;
}

.block-2 {
  grid-area: block-2;
}

.block-3 {
  grid-area: block-3;
}

.block-4 {
  grid-area: block-4;
}

.block-5 {
  grid-area: block-5;
}

.block-6 {
  grid-area: block-6;
}

.timer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: ". ." ". circle";
  grid-area: timer;
}

.circle {
  grid-area: circle;
}

.status {
  grid-area: status;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;

  p:first-child {
    font-weight: bold;
    font-size: 1.2em;
  }

  .online {
    color: green;
  }

  .offline {
    color: red;
  }
}