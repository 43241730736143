.legacycomponent {
  margin: 0;
  padding: 0;
  height:100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  transform-origin: top left;
}

#kiosk {
  height: 1080px;
  width: 1920px;
  overflow: hidden;
  font: 13px Helmet, Freesans, sans-serif;
  color: #333;
  background: url('../../../assets/legacy/sos/backgrounds/seasons/spring.png') repeat 0 0;
  background-color: #737373;

  div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }

  article, aside, figure, footer, header, hgroup, nav, section {
    display: block;
  }

  img,
  object,
  embed {
    max-width: 100%;
  }

  ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }

  a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }

  del {
    text-decoration: line-through;
  }

  abbr[title], dfn[title] {
    border-bottom: 1px dotted #000;
    cursor: help;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  th {
    font-weight: bold;
    vertical-align: bottom;
  }

  td {
    font-weight: normal;
    vertical-align: top;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
  }

  input, select {
    vertical-align: middle;
  }

  pre {
    white-space: pre; /* CSS2 */
    white-space: pre-wrap; /* CSS 2.1 */
    white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
    word-wrap: break-word; /* IE */
  }

  input[type="radio"] {
    vertical-align: text-bottom;
  }

  input[type="checkbox"] {
    vertical-align: bottom;
    *vertical-align: baseline;
  }

  .ie6 input {
    vertical-align: text-bottom;
  }

  select, input, textarea {
    font: 99% sans-serif;
  }

  table {
    font-size: inherit;
  }

  a:hover, a:active {
    outline: none;
  }

  small {
    font-size: 85%;
  }

  strong, th {
    font-weight: bold;
  }

  td, td img {
    vertical-align: top;
  }

  sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25em;
  }

  pre, code, kbd, samp {
    font-family: monospace, sans-serif;
  }

  .clickable,
  label,
  input[type=button],
  input[type=submit],
  button {
    cursor: pointer;
  }

  button, input, select, textarea {
    margin: 0;
  }

  button {
    width: auto;
    overflow: visible;
  }

  .ie7 img {
    -ms-interpolation-mode: bicubic;
  }

  .clearfix:before, .clearfix:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden;
  }

  .clearfix:after {
    clear: both;
  }

  .clearfix {
    zoom: 1;
  }

  select, input, textarea {
    color: #333;
  }

  a {
    color: #03f;
  }

  a:hover {
    color: #69f;
  }

  ::-moz-selection {
    background: #fcd700;
    color: #fff;
    text-shadow: none;
  }

  ::selection {
    background: #fcd700;
    color: #fff;
    text-shadow: none;
  }

  a:link {
    -webkit-tap-highlight-color: #fcd700;
  }

  ins {
    background-color: #fcd700;
    color: #000;
    text-decoration: none;
  }

  mark {
    background-color: #fcd700;
    color: #000;
    font-style: italic;
    font-weight: bold;
  }


  header {
    padding: 50px 0;
    z-index: 10;
  }

  header h1 {
    float: left;
    width: 150px;
    margin-right: 50px;
  }


  footer {
    width: 850px;
    margin: 0 auto;
    padding: 10px 0;
    border-top: 1px solid #ccc;
    color: #ddd;
  }

  nav {
    float: right;
    display: -webkit-box;
    -webkit-box-orient: horizontal;
  }

  nav a {
    display: inline-block;
    margin-left: 25px;
    /* -webkit-box-flex: 1; */
    -webkit-transition: color .4s linear;
    text-align: center;
    text-decoration: none;
    font-family: sans-serif;
    font-weight: bold;
    color: rgb(6, 154, 57); /* #1c9c41; */
  }

  nav a:hover {
    color: #f07e1c; /* #8a1819; */
  }

  nav a.active {
    color: #f07e1c; /* #8a1819; */
  }

  aside {
    float: right;
    width: 400px;
    margin: 20px 0;
    color: white;
  }

  h3 {
    font-size: 12pt;
    color: #f07e1c; /* #8a1819; */
  }

  h4 {
    margin-top: 25px;
    font-size: 14pt;
    color: #f07e1c; /* #8a1819; */
  }


  ul.bullet a {
    color: white !important;
  }

  ul.bullet a:hover {
    color: #f07e1c !important;
  }

  .wrapper {
    position: relative;
    width: 850px;
    min-height: 343px;
    margin: 0 auto;
  }

  .wrapper p {
    margin-bottom: 15px;
    line-height: 13pt;
  }

  .wrapper ol {
    margin-bottom: 15px;
  }

  .wrapper ul {
    margin-bottom: 15px;
    list-style-type: square;
  }

  .wrapper li {
    margin-left: 20px;
  }

  aside a,
  .wrapper .text a {
    color: #f07e1c; /* #8a1819; */
    text-decoration: none;
    font-weight: bold;
  }

  aside a:hover,
  .wrapper .text a:hover {
    color: white;
  }

  .top-wrapper .text {
    float: left;
    width: 550px;
    margin: 50px 20px 20px 0;
    color: rgb(0, 134, 178); /* #1f88b2; */
  }

  .bottom-wrapper .text {
    float: left;
    padding: 20px 0;
    width: 550px;
    color: white;
  }

  .top-wrapper .text h2 {
    margin-bottom: 10px;
    font-size: 18pt;
    font-family: verdana;
    color: rgb(6, 154, 57); /* #1c9c41; */
  }

  .top-wrapper .graphic {
    float: right;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 300px;
    height: 230px;
    z-index: 1;
  }

  .top-wrapper .graphic2 {
    float: right;
    position: absolute;
    right: 0;
    top: 0;
    width: 300px;
    height: 160px;
    z-index: 1;
  }
  .top-wrapper .graphic .canvas {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .top-wrapper .graphic .tree {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 200px;
    height: 200px;
    background: url('../../../assets/legacy/sos/media/tree.png') no-repeat 0 0;
  }


  .wrapper img.border {
    margin: 15px 0;
    background-color: #fff;
    border: 10px solid #fff;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
  }
  label {
    display: block;
    margin-top: 10px;
  }

  input[type="text"], textarea {
    width: 100%;
  }

  input, textarea {
    padding: 5px;
    border: 1px solid #ccc;
    font: 15px/21px "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  #contact_form textarea {
    height: 150px;
  }

  #contact_form input[type="submit"] {
    float: right;
    margin: 10px -10px 0 0;
    background-color: #fff;
    color: #555;
  }

  .cureng, .curco2 {
    font-size: 15pt;
  }

  .cureng img {
    padding-left: 10px;
    vertical-align: bottom;
  }

  .kwh {
    font-size: 22pt;
    font-weight: bold;
  }

  .whitebox {
    position: relative;
    margin: 20px 0;
    padding: 10px;
    min-height: 60px;
    background-color: white;
    border-top: 15px solid #f07e1c;
    border-radius: 25px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    color: #f07e1c; /* #8a1819; */
    background: #ededed; /* Old browsers */
    background: -moz-linear-gradient(left, #ededed 0%, #ffffff 50%, #ededed 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, #ededed), color-stop(50%, #ffffff), color-stop(100%, #ededed)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, #ededed 0%, #ffffff 50%, #ededed 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, #ededed 0%, #ffffff 50%, #ededed 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left, #ededed 0%, #ffffff 50%, #ededed 100%); /* IE10+ */
    background: linear-gradient(to right, #ededed 0%, #ffffff 50%, #ededed 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ededed', endColorstr='#ededed', GradientType=1); /* IE6-9 */
  }

  .video {
    margin-top: 20px;
    width: 340px;
    height: 220px;
    border-top: 5px solid #f07e1c;
  }

  .halfpage {
    width: 400px !important;
  }

  #map_canvas {
    width: 100%;
    height: 250px;
    margin: 0 0 20px 0;
  }

  li.school a {
    color: #f07e1c;
    font-weight: bold;
    text-decoration: none;
  }

  li.school a:hover {
    color: white;
  }

  .infographic {
    width: 360px;
    font-size: 12pt;
  }

  .infographic img {
    margin-right: 5px;
  }

  .infographic img:last-child {
    margin-right: 0;
  }

  .logos,
  .carousel {
    position: relative;
    margin: 0 40px;
    text-align: center;
  }

  .logos a:hover {
    color: #f07e1c !important;
    text-decoration: underline !important;
  }

  .logos li,
  .carousel li {
    margin: 0;
  }

  .logos li img {
    /*margin: 0 7px;*/
    width: 300px;
    height: 100px;
  }

  .carousel li img {
    margin: 0 7px;
    width: 134px;
    height: 100px;
  }


  .logo-next {
    top: 65px;
  }

  .logo-next-disabled,
  .carousel-next-disabled {
    background-position: 0 -20px;
    cursor: default;
  }


  .logo-prev {
    top: 65px;
  }

  .logo-prev-disabled,
  .carousel-prev-disabled {
    background-position: 0 -20px;
    cursor: default;
  }

  #chart {
    margin-top: 10px;
    width: 680px;
    height: 300px;
  }

  #minichart {
    width: 100%;
    height: 250px;
  }

  #chartoverlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
  }

  #bigchart {
    width: 684px;
    height: 350px;
    position: absolute;
    top: 332px;
    left: 108px;
  }

  .quote {
    margin: 35px 0;
  }

  .chart-menu ul {
    list-style-type: none;
  }

  .chart-menu li {
    float: left;
    margin: 0 10px 0 0;
  }

  .chart-menu a {
    display: inline-block;
    padding: 10px;
    min-width: 75px;
    line-height: 20px;
    background-color: #1c9c41;
    text-align: center;
    font-weight: normal !important;
    color: white !important;
  }

  .chart-menu a.selected {
    background-color: #f07e1c;
  }

  h2 {
    color: #f07e1c;
    font-size: 0.99em;
    margin-bottom: 10px;
  }


  #weather {
    width: 684px;
    height: 249px;
    position: absolute;
    top: 749px;
    left: 108px;
  }


  #rain {
    width: 262px;
    height: 246px;
  }

  #weatherforecast {
    width: 262px;
    height: 226px;
    padding-left: 75px;
    padding-top: 20px;
  }


  #electricity {
    position: absolute;
    top: 222px;
    left: 778px;
    width: 218px;
  }

  #sun {
    position: absolute;
    top: 18px;
    right: 299px;
    width: 107px;
  }


  .infobox {
    font-size: 2.5em;
    font-weight: bold;
  }


  #actueel {
    position: absolute;
    top: 332px;
    left: 947px;
    width: 860px;
    height: 128px;
    padding: 20px 30px;
  }

  #actueel h2 {
    /*	position:absolute; */
    margin-bottom: 0;
  }

  #opgewekt {
    position: absolute;
    top: 558px;
    left: 947px;
    height: 83px;
    width: 377px;
    padding: 30px;
    background-image: url('../../../assets/legacy/sos/media/house.png');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-position: 293px 8px;
  }

  #bespaard {
    position: absolute;
    top: 558px;
    left: 1413px;
    height: 83px;
    width: 377px;
    padding: 30px;
    background-image: url('../../../assets/legacy/sos/media/tree.png');
    background-repeat: no-repeat;
    background-position: bottom right;
  }

  #schoolnieuws {
    position: absolute;
    top: 749px;
    left: 947px;
    width: 860px;
    height: 229px;
    padding: 20px 30px;
  }

  #logo {
    position: absolute;
    top: 100px;
    left: 368px;
    max-width: 141px;
    max-height: 49px;
    width: auto;
    height: auto;
  }

  /*
  .bjqs{
      padding-top:40px !important;
  }
  */

  #actueel-slideshow {
    height: 250px;
    width: 420px;
    position: relative;
  }

  #actueel-slideshow img {
    top: 85px;
    max-width: 400px;
    max-height: 140px;
    width: auto;
    display: block;
    clear: both;
    margin: 0 auto;
  }

  .actueel-metafor {
    height: 250px;
    width: 420px;
    display: block;

  }

  #actualPower {
    color: #2ab5b2;
  }

  #currentPower, #currentUsage, .value {
    color: #2ab5b2;
  }

  #logo7h {
    width: 290px;
    position: absolute;
    top: 295px;
    height: auto;
    left: 1560px;
  }

  #evenwicht div {
    text-align: center;
  }

  #evenwicht img {
    max-height: 133px;
  }


  #stekker {
    width: 671px;
    height: 705px;
    background-image: url("../../../assets/legacy/sos/media/stekkeranimatie.png");
    -webkit-animation: play 5s steps(7) infinite;
    -moz-animation: play 5s steps(7) infinite;
    -ms-animation: play 5s steps(7) infinite;
    -o-animation: play 5s steps(7) infinite;
    animation: play 5s steps(7) infinite;
    /*z-index: 1; */
    position: absolute;
    top: -14px;
    left: 544px;
    /*    transform: scale(0.35,0.35); */
    -webkit-transform: scale(0.35);
    -moz-transform: scale(0.35);
    -ms-transform: scale(0.35);
    -o-transform: scale(0.35);
    transform: scale(0.35);
  }

  @-webkit-keyframes play {
    from {
      background-position: -4697px;
    }
    to {
      background-position: 0px;
    }
  }

  @-moz-keyframes play {
    from {
      background-position: -4697px;
    }
    to {
      background-position: 0px;
    }
  }

  @-ms-keyframes play {
    from {
      background-position: -4697px;
    }
    to {
      background-position: 0px;
    }
  }

  @-o-keyframes play {
    from {
      background-position: -4697px;
    }
    to {
      background-position: 0px;
    }
  }

  @keyframes play {
    from {
      background-position: -4697px;
    }
    to {
      background-position: 0px;
    }
  }


  #schoolnieuws > h2 {
    margin-bottom: 6px;
  }

  #message {
    font-size: 26px;
    line-height: 32px;
    display: inline-block;
  }

  #totalbox {
    font-size: 2.5em;
    line-height: 1.0em;
    color: #2ab5b2;
    font-weight: bold;
    margin-left: 20px;
    margin-top:20px;
  }

  #totalbox img {
    position: absolute;
  }

  #house {
    /*	top: 40px;
        left: 508px; */
    top: 40px;
    left: 532px;
    top: 68px;
    width: 53px;
    width: 158px;
    height: auto;
  }

  #tree {
    /*	top: 196px;
        left: 479px; */
    top: 228px;
    top: 207px;
    left: 519px;
    width: 87px;
    width: 150px;

    height: auto;
  }


  #actueel {
    width: 420px;
    height: 330px;
  }

  /*
  .bjqs{
      padding-top:112px !important;
  }
  */

  #equalsign {
    /*	position: absolute;
        top: 54px; */
    font-size: 2em;
    /*	left: 197px;	 */
    color: #f07e1c;
    display: block;
    margin: 0 auto;
    width: 39px;
  }

  #verbruik {
    top: 330px;
    width: 400px;
    left: 1447px;
    height: 136px;
  }

  #evenwicht {
    width: 400px;
    height: 160px;
    left: 1447px;
    top: 330px;
  }

  #message {
    font-weight: normal;
    color: #2ab5b2;
  }

  #totalPower, #totalTrees {
    margin-left: 10px;
  }

}